import { EventEmitter } from 'events'
import Vue from 'vue'

class ReCaptcha {
  constructor ({ hideBadge, language, mode, siteKey, version, size }) {
    if (!siteKey) {
      throw new Error('ReCaptcha error: No key provided')
    }

    if (!version) {
      throw new Error('ReCaptcha error: No version provided')
    }

    this._elements = {}
    this._grecaptcha = null

    this._eventBus = null
    this._ready = false

    this.hideBadge = hideBadge
    this.language = language

    this.siteKey = siteKey
    this.version = version
    this.size = size

    this.mode = mode
  }

  destroy () {
    if (this._ready) {
      this._ready = false

      const { head } = document
      const { style } = this._elements

      const scripts = [...document.head.querySelectorAll('script')]
        .filter(script => script.src.includes('recaptcha'))

      if (scripts.length) {
        scripts.forEach(script => head.removeChild(script))
      }

      if (head.contains(style)) {
        head.removeChild(style)
      }

      const badge = document.querySelector('.grecaptcha-badge')
      if (badge) {
        badge.remove()
      }
    }
  }

  checkGRecaptcha () {
    this._grecaptcha = window.grecaptcha?.enterprise || window.grecaptcha;
    this._ready = !!this._grecaptcha;
    return this._ready;
  }

  async execute (action) {
    try {
      if (!this._ready || !this.checkGRecaptcha()) {
        await this.init();
      }

      if ('grecaptcha' in window) {
        return this._grecaptcha.execute(
          this.siteKey,
          { action }
        )
      }
    } catch (error) {
      throw new Error(`ReCaptcha error: Failed to execute ${error}`)
    }
  }

  getResponse (widgetId) {
    return new Promise((resolve, reject) => {
      if ('grecaptcha' in window) {
        if(this.size == 'invisible'){
          this._grecaptcha.execute(widgetId)

          window.recaptchaSuccessCallback = token => {
            this._eventBus.emit('recaptcha-success', token)
            resolve(token)
          }

          window.recaptchaErrorCallback = error => {
            this._eventBus.emit('recaptcha-error', error)
            reject(error)
          }
        } else {
          const response = this._grecaptcha.getResponse(widgetId)

          if (response) {
            this._eventBus.emit('recaptcha-success', response)
            resolve(response)
          } else {
            const errorMessage = 'Failed to execute'

            this._eventBus.emit('recaptcha-error', errorMessage)
            reject(errorMessage)
          }
        }
      }
    })
  }

  init () {
    if (this._ready) {
      // make sure caller waits until recaptcha get ready
      return this._ready
    }

    try {
    this._eventBus = new EventEmitter();
    this._elements = {
      script: document.createElement('script'),
      style: document.createElement('style')
    }

    const { script, style } = this._elements

    script.setAttribute('async', '')
    script.setAttribute('defer', '')

    const params = []
    if (this.version === 3) { params.push('render=' + this.siteKey) }
    if (this.language) { params.push('hl=' + this.language) }

    let scriptUrl = this.mode === 'enterprise' ? 'https://www.google.com/recaptcha/enterprise.js' : 'https://www.recaptcha.net/recaptcha/api.js';

    script.setAttribute('src', scriptUrl + '?' + params.join('&'))

    window.recaptchaSuccessCallback = (token) => this._eventBus.emit('recaptcha-success', token)
    window.recaptchaExpiredCallback = () => this._eventBus.emit('recaptcha-expired')
    window.recaptchaErrorCallback = () => this._eventBus.emit('recaptcha-error', 'Failed to execute')

    const styleId = 'grecaptcha-badge-style';
    if (!document.getElementById(styleId)) {
      style.id = styleId;
      if (this.version === 3 && this.hideBadge) {
        style.innerHTML = '.grecaptcha-badge { display: none }'
        document.head.appendChild(style)
      } else if(this.version === 2 && this.hideBadge) {
        // display: none DISABLES the spam checking!
        // ref: https://stackoverflow.com/questions/44543157/how-to-hide-the-google-invisible-recaptcha-badge
        style.innerHTML = '.grecaptcha-badge { visibility: hidden; }'
        document.head.appendChild(style)
      }
    }

    new Promise((resolve, reject) => {
      script.addEventListener('load', () => {
        this.checkGRecaptcha();
        this._grecaptcha.ready(resolve)
      })
      script.addEventListener('error', () => {
        document.head.removeChild(script)
        reject('ReCaptcha error: Failed to load script')
        this._ready = null;
      })
      document.head.appendChild(script)
    })
    } catch (error) {
      throw new Error(`ReCaptcha error: Failed to init script ${error}`)
    }

    return this.checkGRecaptcha();
  }

  on (event, callback) {
    return this._eventBus.on(event, callback)
  }

  reset (widgetId) {
    if (this.version === 2 || typeof widgetId !== 'undefined') {
      this._grecaptcha.reset(widgetId)
    }
  }

  render (reference, { sitekey, theme }) {
    return this._grecaptcha.render(reference.$el || reference, { sitekey, theme })
  }
}

export default function (_, inject) {
  const { recaptcha = {} } = _.$config || {}
  const options = {
    ...{"isEnabled":false,"siteKey":"xxx","secretkey":"xxx","version":3,"score":0.9,"size":"invisible","hideBadge":true,"mode":"enterprise"},
    ...recaptcha,
  }

  Vue.component('Recaptcha', () => import('./recaptcha.vue'))
  inject('recaptcha', new ReCaptcha(options))
}
