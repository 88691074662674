import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _8f04537e = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "" */))
const _544757fc = () => interopDefault(import('../pages/Cart.vue' /* webpackChunkName: "pages/Cart" */))
const _0b2841e0 = () => interopDefault(import('../pages/Category.vue' /* webpackChunkName: "pages/Category" */))
const _48ef6e28 = () => interopDefault(import('../pages/Checkout.vue' /* webpackChunkName: "" */))
const _26e24f74 = () => interopDefault(import('../pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _74dcbd02 = () => interopDefault(import('../pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _15bc54f6 = () => interopDefault(import('../pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _7855b4db = () => interopDefault(import('../pages/Checkout/UserAccount.vue' /* webpackChunkName: "pages/Checkout/UserAccount" */))
const _b1da17a4 = () => interopDefault(import('../pages/Contact.vue' /* webpackChunkName: "pages/Contact" */))
const _40a54604 = () => interopDefault(import('../pages/CookieDeclaration.vue' /* webpackChunkName: "pages/CookieDeclaration" */))
const _49f749ef = () => interopDefault(import('../pages/DesignSystem.vue' /* webpackChunkName: "" */))
const _32a92eee = () => interopDefault(import('../pages/SizeGuide.vue' /* webpackChunkName: "" */))
const _10189cef = () => interopDefault(import('../pages/MyAccount.vue' /* webpackChunkName: "pages/MyAccount" */))
const _08b2ebf1 = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _4bba9d7d = () => interopDefault(import('../pages/Product.vue' /* webpackChunkName: "pages/Product" */))
const _693350d0 = () => interopDefault(import('../pages/ResetPassword.vue' /* webpackChunkName: "" */))
const _34cba952 = () => interopDefault(import('../pages/Route.vue' /* webpackChunkName: "pages/Route" */))
const _6f8b500a = () => interopDefault(import('../pages/Search.vue' /* webpackChunkName: "pages/Search" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/it",
    component: _8f04537e,
    meta: {"hideNavigation":true},
    name: "home___it"
  }, {
    path: "/it/Cart",
    component: _544757fc,
    name: "Cart___it"
  }, {
    path: "/it/Category",
    component: _0b2841e0,
    name: "Category___it"
  }, {
    path: "/it/checkout",
    component: _48ef6e28,
    name: "checkout___it",
    children: [{
      path: "billing",
      component: _26e24f74,
      name: "billing___it"
    }, {
      path: "payment",
      component: _74dcbd02,
      name: "payment___it"
    }, {
      path: "shipping",
      component: _15bc54f6,
      name: "shipping___it"
    }]
  }, {
    path: "/it/Checkout",
    component: _48ef6e28,
    name: "Checkout___it",
    children: [{
      path: "Billing",
      component: _26e24f74,
      name: "Checkout-Billing___it"
    }, {
      path: "Payment",
      component: _74dcbd02,
      name: "Checkout-Payment___it"
    }, {
      path: "Shipping",
      component: _15bc54f6,
      name: "Checkout-Shipping___it"
    }, {
      path: "UserAccount",
      component: _7855b4db,
      name: "Checkout-UserAccount___it"
    }]
  }, {
    path: "/it/Contact",
    component: _b1da17a4,
    name: "Contact___it"
  }, {
    path: "/it/contattaci",
    component: _b1da17a4,
    name: "Contattaci___it"
  }, {
    path: "/it/CookieDeclaration",
    component: _40a54604,
    name: "CookieDeclaration___it"
  }, {
    path: "/it/cookies-policy",
    component: _40a54604,
    name: "cookie-declaration___it"
  }, {
    path: "/it/design-system",
    component: _49f749ef,
    meta: {"hideNavigation":true},
    name: "Design System___it"
  }, {
    path: "/it/DesignSystem",
    component: _49f749ef,
    name: "DesignSystem___it"
  }, {
    path: "/it/guida-taglie",
    component: _32a92eee,
    name: "Guida Taglie___it"
  }, {
    path: "/it/Home",
    component: _8f04537e,
    name: "Home___it"
  }, {
    path: "/it/MyAccount",
    component: _10189cef,
    name: "MyAccount___it"
  }, {
    path: "/it/Page",
    component: _08b2ebf1,
    name: "Page___it"
  }, {
    path: "/it/Product",
    component: _4bba9d7d,
    name: "Product___it"
  }, {
    path: "/it/reset-password",
    component: _693350d0,
    alias: "/customer/account/createPassword",
    name: "reset-password___it"
  }, {
    path: "/it/ResetPassword",
    component: _693350d0,
    name: "ResetPassword___it"
  }, {
    path: "/it/Route",
    component: _34cba952,
    name: "Route___it"
  }, {
    path: "/it/Search",
    component: _6f8b500a,
    name: "Search___it"
  }, {
    path: "/it/search-result",
    component: _6f8b500a,
    name: "search-result___it"
  }, {
    path: "/it/SizeGuide",
    component: _32a92eee,
    name: "SizeGuide___it"
  }, {
    path: "/it/c/:categoryId",
    component: _0b2841e0,
    name: "category___it"
  }, {
    path: "/it/my-account/:pageName?",
    component: _10189cef,
    name: "my-account___it"
  }, {
    path: "/it/p/:productId",
    component: _4bba9d7d,
    name: "product___it"
  }, {
    path: "/it/:path+",
    component: _34cba952,
    name: "route-resolver___it"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
